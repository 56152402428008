<script setup lang="ts">
import { usePaymentApi } from "@authLayer/composables/usePaymentApi";

const { data, status } = usePaymentApi<{
  data: {
    id: number
    name: string
    code: string
    rate_usdt: string
    rate_updated_at: string
  }[]
}>("/currencies");

const uahRate = computed(() => {
  const uah = data.value?.data.find(el => el.code === 'UAH');
  let rate = '';
  let rateUpdatedAt = '';

  if (uah) {
    rate = uah.rate_usdt;
    rateUpdatedAt = uah.rate_updated_at;
  }

  return {
    rate,
    rateUpdatedAt
  }
});

const inrRate = computed(() => {
  const inr = data.value?.data.find(el => el.code === 'INR');
  let rate = '';
  let rateUpdatedAt = '';

  if (inr) {
    rate = inr.rate_usdt;
    rateUpdatedAt = inr.rate_updated_at;
  }

  return {
    rate,
    rateUpdatedAt
  }
});

const currencies = computed(() => ({
  UAH: {
    icon: 'twemoji:flag-ukraine',
    rate: uahRate.value.rate,
    rateUpdatedAt: uahRate.value.rateUpdatedAt
  },
  INR: {
    icon: 'twemoji:flag-india',
    rate: inrRate.value.rate,
    rateUpdatedAt: inrRate.value.rateUpdatedAt
  }
}
));
</script>

<template>
  <div>
    <UISpinner v-if="status === 'pending'" size="small" />
    <template v-else>
      <UITooltip>
        <template #trigger>
          <div :class="$style.exchangeRateContainer">
            <div v-for="(value, key) in currencies" :key="key" :class="$style.exchangeRate">
              <Icon :name="value.icon" size="20" />
              <p :class="$style.exchangeRateValue">{{ value.rate }}</p>
            </div>
          </div>
        </template>
        <template #default>
          <div>
            <p>Rate updated at</p>
            <UIDateComponent :date="uahRate.rateUpdatedAt" />
          </div>
        </template>
      </UITooltip>
    </template>
  </div>
</template>

<style module>
.exchangeRateContainer {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.exchangeRate {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.exchangeRateValue {
  font-size: 0.75rem;
  line-height: 1;
}
</style>
